.pageTitle {
	color: #333333;
	display: block;
	font-size: 2.4rem;
	text-transform: uppercase;
	letter-spacing: 0.3rem;
	margin-bottom: 1.5rem;
}

.pageSubtitle {
	color: #333333;
	display: block;
	font-size: 1.2rem;
	margin-bottom: 3rem;
}