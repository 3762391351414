.chooseCompanyTitle {
	font-size: 1.4rem;
	display: block;
}

.chooseCompanySubtitle {
	display: block;
	margin-bottom: 2rem;
}

.routeButton {
	display: block;
	border: none;
	cursor: pointer;
	transition: 0.1s ease-in-out;
}

.routeButton:hover {
	filter: opacity(0.8);
}