.stopHolder {
	display: block;
	padding: 1rem;
	background-color: #dbdbdb;
	line-height: 2rem;
	vertical-align: middle;
	border-radius: 2rem;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	margin-bottom: 1.5rem;
}

.stopHolderButton {
	display: block;
	width: 100%;
	text-align: left;
	border: none;
	padding: 1rem;
	background-color: #dbdbdb;
	line-height: 2rem;
	vertical-align: middle;
	border-radius: 2rem;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	margin-bottom: 1.5rem;
	cursor: pointer;
	transition: 0.1s ease-in-out;
}

.stopHolderButton:hover {
	filter: opacity(0.8);
}

.stopNumber {
	display: inline-block;
	color: #f2f2f2;
	background-color: #726205;
	padding: 0rem 0.8rem;
	margin-right: 1.5rem;
	font-size: 0.8rem;
	border-radius: 1.5rem;
	line-height: 2rem;
	vertical-align: middle;
}

.stopName {
	color: #333333;
	font-size: 1.3rem;
	line-height: 2rem;
	vertical-align: middle;
}