.routeOuter {
	display: inline-block;
	padding: 0.1rem 0.8rem;
	border-radius: 0.5rem;
}

.Citybus {
	background-color: #e5d82c;
	color: #333333;
}

.KMB {
	background-color: #d4171d;
	color: #f2f2f2;
}

.NWFB {
	background-color: #e77422;
	color: #f2f2f2;
}

.GMB {
	background-color: #1a8068;
	color: #f2f2f2;
}

.Cityflyer {
	background-color: #dab216;
	color: #a81008;
}

.CityflyerOvernight {
	background-color: #a81008;
	color: #dab216;
}

.LWB {
	background-color: #ca5b05;
	color: #f2f2f2;
}

.LWBOvernight {
	background-color: #333333;
	color: #d36c1e;
}

.Default {
	background-color: #f2f2f2;
	color: #333333;
}

.margins {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}

.boxShadow {
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}