.noPadding {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 5rem;
	left: 0;
	right: 0;
}

.stopMapPadding {
	display: block;
	padding: 2rem;
	position: relative;
	height: calc(100% - 25rem);
	top: -2rem;
	background-color: #f2f2f2;
	z-index: 50;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
	overflow-y: auto;
}

@media only screen and (max-height: 700px) {
	.stopMapPadding {
		height: calc(100% - 20rem);
	}
}

.minibusNotice {
	display: block;
	color: #333333;
	margin-bottom: 3rem;
	font-size: 1.1rem;
}